body {
  .app {
    background-color: #F2F2F2;

    .btn-submit {
      background-color: #0059a9 !important;
      border: 1px solid #0059a9 !important;
      border-radius: 20px !important;
    }

    .custom-border-card {
      margin-top: 15vh !important;
    }

    .card {
      border-radius: 15px;
    }
  }
}