.site-ship-page {

  .custom-table-sr {
    max-height: calc(100vh - 190px);
    min-height: 100%;
    overflow: scroll;


    tbody {
      background-color: white;

      td {
        padding: 5px !important;
        white-space: nowrap !important;
        justify-content: center;
      }
    }
  }
}