body {
  button:focus {
    outline: 0 !important;
  }

  .your-component {
    background: #f2f2f2;
  }

  .mobile-view {
    width: fit-content;
  }

  padding: 0;
  margin: 0;
  font-family: roboto, serif !important;
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* width */
  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #0059a9;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #0059a9;
  }

  .custom-btn-icon {
    padding: 5px 0 !important;
  }

  .custom-icon {
    width: 35px !important;
    height: 35px !important;
  }

  .custom-bg-filter {
    background-color: #f8f8fb;
    padding: 5px 15px 15px 15px;
    font-size: 13px;
    font-weight: 500;

    input {
      width: 160px !important;
    }

    .ant-select {
      width: 160px !important;
    }
  }

  .table > :not(:first-child) {
    border-top: none !important;
  }

  .custom-table {
    margin-bottom: 0;

    thead {
      font-size: 12px !important;
      vertical-align: middle;
      text-align: center;
      background-color: #cdcdcd;
    }

    td {
      padding: 5px !important;
      white-space: nowrap !important;
      justify-content: center;

      img {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .navbar > .container {
    max-width: 100%;
    margin: 5px 6px;
    padding: 0;

    .nav-link {
      min-width: 100px;
    }
  }

  .button-container {
    display: flex;
    margin-top: 10px;
    justify-content: space-between;

    span {
      font-family: Roboto, serif !important;
    }
  }

  @media (width < 1200px) {
    .button-container {
      flex-wrap: wrap;
      justify-content: flex-start;

      .custom-btn-update {
        margin-left: 10px;
      }
    }
  }

  @media (width > 1201px) {
    .button-container {
      .custom-btn-update {
        margin-left: auto;
      }
    }
  }

  .custom-btn-primary {
    color: white;
    border: #0059a9 1px solid;
    background-color: #0059a9;

    &:hover {
      color: white !important;
    }
  }

  .custom-btn-outline-primary {
    color: #0059a9;
    border: #0059a9 1px solid;

    .custom-icon-update {
      width: 16px;
    }
  }

  .custom-btn-outline-primary:hover {
    border: #0059a9 1px solid;
    color: white !important;
    background-color: #0059a9;

    .custom-icon-update {
      width: 16px;
      color: white;
    }
  }

  .custom-btn,
  .custom-btn-update {
    border-radius: 25px;
    margin-top: 10px;
    font-weight: bold;
  }

  .home-container {
    .custom-table-sr {
      max-height: calc(100vh - 120px);
      overflow: scroll;

      tbody {
        background-color: white;
      }
    }

    .dropdown-toggle::after {
      content: none;
    }

    .header-container {
      .bg-body-tertiary {
        padding: 0 15px;
      }

      .dropdown-menu[data-bs-popper] {
        left: -100px;
      }
    }

    .hide-element {
    }

    .custom-row-paginate {
      height: 50px;
      justify-items: center;
      padding: 0 15px;
      background: #f8f8fb;
      font-size: 14px !important;

      .col-12 {
        height: 100%;

        .d-flex.align-items-center {
          height: 100%;
        }
      }

      .row {
        --bs-gutter-x: 0;
      }
    }
  }

  .pswp__button--custom-rotate-button-right,
  .pswp__button--custom-rotate-button {
    top: 8px;
    left: 10px;
  }

  .pswp__button--custom-close-button {
    margin-left: auto;
  }

  .pswp__default-caption.pswp__hide-on-close {
    //position: sticky !important;
    display: none;
  }

  .pswp__counter {
    display: none;
  }

  .pswp__preloader {
    display: none;
  }

  .pswp__top-bar {
    justify-content: center !important;
    bottom: 10px !important;
    top: auto !important;

    .pswp__button--close {
      display: none;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
