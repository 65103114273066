table {
  font-size: 12px;
  border-collapse: collapse;
  font-weight: 500;

  thead {
    border-collapse: collapse;
    border-spacing: 0;

    tr th {
      position: sticky;
      top: 0;
      background-color: #cdcdcd !important;
      padding: 6px;
      border: 1px solid #e6e6e6 !important;
      text-align: center;
    }

    tr:nth-child(2) th {
      z-index: 1000;
      top: 53px;
      text-align: center;
    }
  }

  tbody tr td {
    padding: 6px;
    border: 1px solid #e6e6e6;
    white-space: nowrap;
  }
}

.ant-input {
  width: 160px;
}

.ant-picker {
  width: 100%;
}

.ant-select {
  width: 100%;
}

.ant-modal-full-screen {
  top: 0 !important;
  height: 100% !important;
  width: 100% !important;
  padding: 20px;
  min-width: 1200px;
  max-width: 100% !important;

  .ant-modal-content {
    left: 0;
    margin: 0;
    height: 100% !important;
    width: 100% !important;
    top: 0;

    .ant-modal-body {
      height: 100% !important;
    }
  }
}

.ant-modal-content {
  padding: 45px !important;
  .ant-modal-body {
    height: 70vh;
    position: relative;
    overflow: hidden;

    .container-preview-image {
      height: 100%;
      position: relative;
      overflow: hidden;
      width: 100%;

      .preview-image {
        height: 100%;
        width: 100%;
        position: absolute;
        object-fit: contain;
      }
    }
  }

  .ant-modal-footer {
    .custom-btn-change-file {
      margin-right: 5px;
      background-color: #e1e9f2;
      border: none;
      width: 50px;
      height: 50px;
      z-index: 9999;
    }
  }
}

.leaflet-right {
  display: none;
}

.tt-cont {
  min-width: 150px;
}

.pagination {
  --bs-pagination-focus-box-shadow: #f8f8fb !important;
  --bs-pagination-bg: #f8f8fb !important;
  --bs-pagination-border-width: 0 !important;
  --bs-pagination-active-bg: #f8f8fb !important;
  --bs-pagination-active-color: #0d6efd !important;
  --bs-pagination-disabled-bg: #f8f8fb !important;
  --bs-pagination-disabled-color: #ced4da !important;
}

.page-item {
  outline: 0 !important;
  height: 25px !important;
  font-weight: bold;

  .page-link {
    font-size: 14px !important;
    height: 30px !important;
    color: #747892;
    padding: 0.15rem 0.75rem;
  }
}

.ant-picker-clear {
  svg {
    fill: rgba(0, 0, 0, 0.25) !important;
  }
}

.ant-popover {
  .ant-popover-title {
    min-width: 220px;
  }
}
